import request from "@/utils/request";
//获取分类
export const pluginCategory = (data) => {
    return request({
        url: "/client_v1/plugin/category",
        method: "POST",
        data
    })
}
//获取插件分类界面所有的平台
export const pluginPlatform = (data) => {
    return request({
        url: "/client_v1/plugin/platform",
        method: "POST",
        data
    })
}
//获取插件列表
export const pluginList = (data) => {
    return request({
        url: "/client_v1/plugin/list",
        method: "POST",
        data
    })
}
//获取插件排行榜总榜
export const leaderboardList = (data) => {
    return request({
        url: "/client_v1/plugin/installs",
        method: "POST",
        data
    })
}
//获取插件排行榜月榜
export const monthLeaderboardList = (data) => {
    return request({
        url: "/client_v1/plugin/month-billboard",
        method: "POST",
        data
    })
}
//获取插件排行榜周榜
export const weekLeaderboardList = (data) => {
    return request({
        url: "/client_v1/plugin/week-billboard",
        method: "POST",
        data
    })
}
//获取插件详情
export const pluginInfo = (data) => {
    return request({
        url: "/client_v1/plugin/info",
        method: "POST",
        data
    })
}
//获取可供插件分配的环境和平台信息
export const pluginAvilableEnvironment = (data) => {
    return request({
        url: "/client_v1/plugin/environment-platform",
        method: "POST",
        data
    })
}
//安装插件
export const pluginInstall = (data) => {
    return request({
        url: "/client_v1/plugin/company-install",
        method: "POST",
        data
    })
}
//为插件分配环境
export const pluginAllocateEnvironment = (data) => {
    return request({
        url: "/client_v1/plugin/allocate-account",
        method: "POST",
        data
    })
}
//启用白名单
export const enableWhitelist = (data) => {
    return request({
        url: "/client_v1/plugin/enable-whitelist",
        method: "POST",
        data
    })
}
//关闭白名单
export const cancelWhitelist = (data) => {
    return request({
        url: "/client_v1/plugin/cancel-whitelist",
        method: "POST",
        data
    })
}
//插件管理获取企业当前已安装插件
export const installPlugin = (data) => {
    return request({
        url: "/client_v1/plugin/manage",
        method: "POST",
        data
    })
}
//卸载已安装插件
export const cancelInstall = (data) => {
    return request({
        url: "/client_v1/plugin/cancel-install",
        method: "POST",
        data
    })
}

//跨境导航分类
export const crossNavCategory = (data) => {
    return request({
        url: "/client_v1/plugin/cross-border-navigation-category",
        method: "POST",
        data
    })
}

//跨境导航列表
export const crossNavList = (data) => {
    return request({
        url: "/client_v1/plugin/cross-border-navigation",
        method: "POST",
        data
    })
}