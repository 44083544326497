<template>
	<a-modal title="分配环境" :visible="visible" :width="652" @cancel="handleCancel">
		<!-- 环境分配 -->
		<div v-if="!isShowWhitelist" class="modal_body">
			<div class="body_left">
				<a-tabs default-active-key="1">
					<a-tab-pane key="1" tab="按环境"
						><div class="left_item">
							<a-select
								show-search
								v-model="environmentSearchVal"
								placeholder="搜索环境名，点击切换选中"
								style="width: 230px"
								:default-active-first-option="false"
								:show-arrow="false"
								:filter-option="false"
								:not-found-content="'无匹配内容'"
								@search="handleEnvironmentSearch"
								@change="handleEnvironmentChange">
								<a-select-option v-for="d in searchEnvironmentData" :key="d.id">
									{{ d.env_name }}<a-icon v-if="d.isChecked" type="check" style="color: #4c84ffed; margin-left: 50px" />
								</a-select-option>
							</a-select>
							<div class="checkbox">
								<a-checkbox :checked="environmentList.every(i => i.isChecked)" @change="checkAll"> 全选 </a-checkbox>
							</div>

							<ul class="environment_list">
								<li
									class="environment_item"
									v-for="item in environmentList"
									:key="item.id"
									@click="item.isChecked = !item.isChecked"
									:class="{
										active: item.isChecked,
									}">
									<span class="environment_item_txt">{{ item.env_name }}</span>
									<a-icon type="check" style="color: #4c84ffed" />
								</li>
							</ul></div
					></a-tab-pane>
					<a-tab-pane key="2" tab="按电商平台"
						><div class="left_item">
							<a-select
								show-search
								v-model="platformSearchVal"
								placeholder="搜索电商平台，点击切换选中"
								style="width: 230px"
								:default-active-first-option="false"
								:show-arrow="false"
								:filter-option="false"
								:not-found-content="'无匹配内容'"
								@search="handlePlatformSearch"
								@change="handlePlatformChange">
								<a-select-option v-for="d in searchPlatformData" :key="d.id">
									{{ d.site_name }}
								</a-select-option>
							</a-select>
							<div class="checkbox">
								<a-checkbox :checked="environmentList.every(i => i.isChecked)" @change="checkAll"> 全选 </a-checkbox>
							</div>
							<ul class="environment_list">
								<li
									class="environment_item"
									v-for="item in platformList"
									:key="item.id"
									@click="togglePlatformChecked(item)"
									:class="{
										active: item.isChecked,
									}">
									<span class="environment_item_txt">{{ item.site_name }}</span
									><a-icon type="check" style="color: #4c84ffed" />
								</li>
							</ul></div
					></a-tab-pane>
				</a-tabs>
			</div>
			<div class="body_right">
				<p class="right_title">已选择账号</p>
				<div class="right_item">
					<a-select
						show-search
						v-model="checkedListSearchVal"
						placeholder="搜索已选择账号，点击取消选中"
						style="width: 230px"
						:default-active-first-option="false"
						:show-arrow="false"
						:filter-option="false"
						:not-found-content="'无匹配内容'"
						@search="handleCheckedListSearch"
						@change="handleCheckedListChange">
						<a-select-option v-for="d in searchCheckedListData" :key="d.id">
							{{ d.env_name }}
						</a-select-option>
					</a-select>
					<ul class="environment_list_checked">
						<li class="environment_item_checked" v-for="item in environmentList.filter(i => i.isChecked)" :key="item.id">
							<span class="environment_item_checked_txt">{{ item.env_name }}</span
							><a-icon type="close" @click="item.isChecked = false" style="color: #4c84ffed; cursor: pointer" />
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- 分配白名单窗口 -->
		<div v-else class="modal_body">
			<div class="body_left">
				<a-tabs default-active-key="1">
					<a-tab-pane key="1" tab="所有员工"
						><div class="left_item">
							<a-select
								show-search
								v-model="memberSearchVal"
								placeholder="搜索成员名，点击切换选中"
								style="width: 230px"
								:default-active-first-option="false"
								:show-arrow="false"
								:filter-option="false"
								:not-found-content="'无匹配内容'"
								@search="handleMemberSearch"
								@change="handleMemberChange">
								<a-select-option v-for="d in searchMemberData" :key="d.id">
									{{ d.real_name }}<a-icon v-if="d.isChecked" type="check" style="color: #4c84ffed; margin-left: 50px" />
								</a-select-option>
							</a-select>
							<div v-if="memberList.length" class="checkbox">
								<a-checkbox :checked="memberList.every(i => i.isChecked)" @change="checkAllMember($event, memberList)"> 全选 </a-checkbox>
							</div>

							<ul class="environment_list">
								<li
									class="environment_item"
									v-for="item in memberList"
									:key="item.id"
									@click="item.isChecked = !item.isChecked"
									:class="{
										active: item.isChecked,
									}">
									<span class="environment_item_txt">{{ item.real_name }}</span
									><a-icon type="check" style="color: #4c84ffed" />
								</li>
							</ul></div
					></a-tab-pane>
					<a-tab-pane key="2" tab="按部门"
						><div class="left_item">
							<!-- 树形部门结构 -->
							<a-tree-select
								v-model="departId"
								style="width: 100%"
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="treeData"
								placeholder="选择一个部门"
								@change="selectDepart">
							</a-tree-select>
							<div v-if="departMemberList.length" class="checkbox">
								<a-checkbox :checked="departMemberList.every(i => i.isChecked)" @change="checkAllMember($event, departMemberList)"> 全选 </a-checkbox>
							</div>
							<ul class="environment_list">
								<li
									class="environment_item"
									v-for="item in departMemberList"
									:key="item.id"
									@click="item.isChecked = !item.isChecked"
									:class="{
										active: item.isChecked,
									}">
									<span class="environment_item_txt">{{ item.real_name }}</span
									><a-icon type="check" style="color: #4c84ffed" />
								</li>
							</ul></div
					></a-tab-pane>
				</a-tabs>
			</div>
			<div class="body_right">
				<p class="right_title">已选择员工</p>
				<div class="right_item">
					<a-select
						show-search
						v-model="checkedMemberSearchVal"
						placeholder="搜索已选择员工，点击取消选中"
						style="width: 230px"
						:default-active-first-option="false"
						:show-arrow="false"
						:filter-option="false"
						:not-found-content="'无匹配内容'"
						@search="handleCheckedMemberSearch"
						@change="handleCheckedMemberChange">
						<a-select-option v-for="d in searchCheckedMemberData" :key="d.id">
							{{ d.real_name }}
						</a-select-option>
					</a-select>
					<ul class="environment_list_checked">
						<li class="environment_item_checked" v-for="item in memberList.filter(i => i.isChecked)" :key="item.id">
							<span class="environment_item_checked_txt">{{ item.real_name }}</span>
							<a-icon type="close" @click="item.isChecked = false" style="color: #4c84ffed; cursor: pointer" />
						</li>
					</ul>
				</div>
			</div>
		</div>
		<template v-if="!isShowWhitelist" slot="footer">
			<span @click="removeAll(environmentList)" style="color: #4c84ffed; margin-right: 30px; cursor: pointer"> 清空已选 </span>
			<span @click="isShowWhitelist = true" style="color: #4c84ffed; margin-right: 30px; cursor: pointer"> 白名单设置 </span>
			<a-button @click="handleCancel"> 取消 </a-button>
			<a-button @click="handleSkip" :loading="skipLoading" v-show="!isInstall"> 跳过分配，先安装 </a-button>
			<a-button type="primary" :loading="confirmLoading" @click="handleOk">
				{{ isInstall ? "重新分配环境" : "分配环境并安装" }}
			</a-button>
		</template>
		<template v-else slot="footer">
			<span @click="removeAll(memberList)" style="color: #4c84ffed; margin-right: 30px; cursor: pointer"> 清空已选 </span>
			<span @click="closeWhitelist" style="color: #4c84ffed; margin-right: 30px; cursor: pointer"> 关闭白名单 </span>
			<a-button @click="isShowWhitelist = false"> 返回 </a-button>
			<a-button type="primary" :loading="confirmLoading" @click="handleWhitelist"> 确认分配白名单 </a-button>
		</template>
	</a-modal>
</template>

<script>
import { pluginAvilableEnvironment, pluginInstall, pluginAllocateEnvironment, enableWhitelist, cancelWhitelist } from "@/api/extension.js";
import { user_cqqq_list, user_listdepartment } from "@/api/member.js";
export default {
	name: "AddExtension",
	props: ["extensionItem", "visible"],
	data() {
		return {
			extensionId: "",
			isInstall: false,
			downloadUrl: "",
			guid: "",
			row: 1,
			width: "",
			confirmLoading: false,
			skipLoading: false,
			environmentList: [],
			platformList: [],
			environmentIds: [], //插件已分配环境，用于判别
			memberIds: [], //插件已分配白名单
			searchEnvironmentData: [],
			searchPlatformData: [],
			searchCheckedListData: [],
			searchMemberData: [],
			searchCheckedMemberData: [],
			environmentSearchVal: undefined,
			platformSearchVal: undefined,
			checkedListSearchVal: undefined,
			memberSearchVal: undefined,
			checkedMemberSearchVal: undefined,
			isShowWhitelist: false,
			//分配白名单所需数据
			isUseWhitelist: false, //是否启用白名单
			memberList: [],
			searchMemberKeyword: "",
			departId: undefined,
			treeData: [],
			departMemberList: [],
			user_role: "",
		};
	},
	created() {
		this.user_role = JSON.parse(localStorage.member).user_role;
	},
	// computed: {
	//   install() {
	//     return this.$store.state.install;
	//   },
	// },
	// watch: {
	//   async install(newVal) {
	//     if (newVal) {
	//       if (this.installType == "dispatch") {
	//         this.dispatchEnvInstall();
	//       }
	//       if (this.installType == "skip") {
	//         this.skipInstall();
	//       }
	//     }
	//   },
	// },
	methods: {
		//websocket通信
		sendToWebsocket() {
			let c_1 = JSON.stringify({
				message: "/connection/downloadPlug",
				id: this.extensionId,
				download_url: this.downloadUrl,
				guid: this.guid,
			});
			// let c_1 = `{"message": "/connection/success"}`;
			// 加密
			// const XORencryption = (val) => {
			// 	if (typeof val !== 'string') return val;
			// 	val = this.$Base64.encode(val);
			// 	var m = 'y';
			// 	let message = '';
			// 	for (var i = 0; i < val.length; i++) {
			// 		if (0 == i % 2) {
			// 			message += String.fromCharCode(val.charCodeAt(i) ^ m.charCodeAt(0));
			// 		} else {
			// 			message += val[i];
			// 		}
			// 	}
			// 	return this.$Base64.encode(message);
			// };
			// let b_1 = XORencryption(c_1);
			let b_1 = this.$encrypt(c_1);
			this.$store.state.websocket.send(b_1);
		},
		toggleEnvironmentChecked(item) {
			item.isChecked = !item.isChecked;
		},
		togglePlatformChecked(item) {
			item.isChecked = !item.isChecked;
			item.environmentList.forEach(i => {
				i.isChecked = item.isChecked;
			});
		},
		//获取公司成员
		async getMemberList() {
			let user_role = JSON.parse(localStorage.getItem("member")).user_role;
			if (user_role != 1) {
				const {
					data: {
						data: { list },
					},
				} = await user_cqqq_list();
				//获取成员信息，用isChecked代表是否被加入白名单
				list.forEach(item => {
					item.isChecked = this.memberIds.includes(item.id) && this.isUseWhitelist;
				});
				this.memberList = list;
			}
		},
		//获取部门信息，生成部门树
		async getDepartTree() {
			const {
				data: {
					data: { list },
				},
			} = await user_listdepartment();
			this.formatTree(list);
			this.treeData = list;
		},
		//递归生成树形结构
		formatTree(list) {
			list.forEach(item => {
				item.key = item.value = item.id;
				if (item.children.length != 0) {
					this.formatTree(item.children);
				}
			});
		},
		//分配环境并安装
		async handleOk() {
			this.confirmLoading = true;
			this.installType = "dispatch";
			if (!this.isInstall) {
				this.sendToWebsocket();
				this.dispatchEnvInstall();
			} else {
				this.allocateEnvironment();
			}
		},
		//分配环境并安装
		async dispatchEnvInstall() {
			const { data } = await pluginInstall({ id: this.extensionId });
			if (data.code == 200) {
				const member_ids = this.memberList.filter(item => item.isChecked).map(i => i.id);
				//判断是否开启白名单
				if (this.isUseWhitelist) {
					enableWhitelist({ id: this.extensionId, member_ids });
				}
				this.allocateEnvironment();
			}
		},
		//分配环境
		async allocateEnvironment() {
			if (!this.auth) {
				this.$message.error("你没有当前插件的操作权限，请联系公司管理员");
				return;
			}
			const environment_id = this.environmentList.filter(i => i.isChecked).map(item => item.id);
			const res = await pluginAllocateEnvironment({
				id: this.extensionId,
				environment_id,
			});
			if (res.data.code == 200) {
				this.confirmLoading = false;
				this.$message.success(this.isInstall ? "已重新分配环境" : "已成功安装并分配环境");
				this.$emit("install");
				this.$emit("close");
			}
		},
		//跳过分配直接安装
		async handleSkip() {
			this.skipLoading = true;
			this.installType = "skip";
			this.sendToWebsocket();
			this.skipInstall();
		},
		//跳过分配直接安装
		async skipInstall() {
			const { data } = await pluginInstall({ id: this.extensionId });
			if (data.code == 200) {
				this.skipLoading = false;
				this.$message.success("已成功安装");
				this.$emit("install");
				this.$emit("close");
			}
		},
		handleCancel() {
			this.$emit("close");
		},
		//全选
		checkAll(event) {
			const val = event.target.checked;
			this.environmentList.forEach(item => {
				item.isChecked = val;
			});
		},
		//清空所选
		removeAll(list) {
			list.forEach(item => {
				item.isChecked = false;
			});
		},
		//点击搜索候选项切换该环境或平台选中状态并清空搜索框内容
		handleEnvironmentChange(val) {
			const item = this.environmentList.find(i => i.id == val);
			this.toggleEnvironmentChecked(item);
			this.environmentSearchVal = undefined;
		},
		handlePlatformChange(val) {
			const item = this.platformList.find(i => i.id == val);
			this.togglePlatformChecked(item, true);
			this.platformSearchVal = undefined;
		},
		//点击搜索候选项取消选中该环境并清空搜索框内容
		handleCheckedListChange(val) {
			const item = this.environmentList.find(i => i.id == val);
			this.toggleEnvironmentChecked(item);
			this.checkedListSearchVal = undefined;
		},
		handleMemberChange(val) {
			const item = this.memberList.find(i => i.id == val);
			item.isChecked = !item.isChecked;
			this.memberSearchVal = undefined;
		},
		handleCheckedMemberChange(val) {
			const item = this.memberList.find(i => i.id == val);
			item.isChecked = false;
			this.checkedMemberSearchVal = undefined;
		},
		//搜索候选项
		handleEnvironmentSearch(val) {
			this.searchEnvironmentData = this.environmentList.filter(i => i.env_name.includes(val));
		},
		handlePlatformSearch(val) {
			this.searchPlatformData = this.platformList.filter(i => i.site_name.includes(val));
		},

		handleCheckedListSearch(val) {
			this.searchCheckedListData = this.environmentList.filter(i => i.isChecked && i.env_name.includes(val));
		},

		handleMemberSearch(val) {
			this.searchMemberData = this.memberList.filter(i => i.real_name.includes(val));
		},
		handleCheckedMemberSearch(val) {
			this.searchCheckedMemberData = this.memberList.filter(i => i.isChecked && i.real_name.includes(val));
		},

		// 选中部门
		async selectDepart(value, label, extra) {
			// const { data } = await user_cqqq_list({ department_id: value });
			this.departMemberList = this.memberList.filter(item => item.depart.find(i => i.id == value));
		},
		//全选成员
		checkAllMember(event, list) {
			const val = event.target.checked;
			list.forEach(item => {
				item.isChecked = val;
			});
		},
		//分配白名单
		handleWhitelist() {
			if (!this.auth) {
				this.$message.error("你没有当前插件的操作权限，请联系公司管理员");
				return;
			}
			this.confirmLoading = true;
			if (this.isInstall) {
				const member_ids = this.memberList.filter(item => item.isChecked).map(i => i.id);
				enableWhitelist({ id: this.extensionId, member_ids }).then(({ data }) => {
					if (data.code == 200) {
						this.confirmLoading = false;
						this.$message.success("已成功分配白名单");
						this.isShowWhitelist = false;
					}
				});
			} else {
				this.isUseWhitelist = true;
				this.confirmLoading = false;
				this.$message.success("已保存白名单，待确认安装");
				this.isShowWhitelist = false;
			}
		},
		//关闭白名单
		closeWhitelist() {
			this.$confirm({
				title: "确定关闭白名单吗?",
				content: "关闭白名单后，所有成员均可享有当前插件的分配权",
				onOk: () => {
					if (this.isInstall) {
						return cancelWhitelist({ id: this.extensionId }).then(({ data }) => {
							if (data.code == 200) {
								this.removeAll(this.memberList);
								this.isShowWhitelist = false;
								this.isUseWhitelist = false;
							}
						});
					} else {
						this.removeAll(this.memberList);
						this.isUseWhitelist = false;
						this.isShowWhitelist = false;
					}
				},
				onCancel() {},
			});
		},
	},
	watch: {
		environmentList: {
			handler() {
				this.platformList.forEach(i => {
					i.isChecked = i.environmentList.every(item => item.isChecked);
				});
			},
			deep: true,
		},
		visible(newVal) {
			//监听visible，如果为true，则说明打开了添加插件窗口，获取当前插件可供分配环境信息
			if (newVal) {
				Object.assign(this.$data, this.$options.data()); //每次打开窗口时重置数据
				this.extensionId = this.extensionItem.id;
				this.isInstall = this.extensionItem.is_install;
				this.downloadUrl = this.extensionItem.download_url;
				this.guid = this.extensionItem.guid;
				pluginAvilableEnvironment({ id: this.extensionId }).then(({ data }) => {
					if (data.code == 200) {
						const platformList = data.data.platform;
						const environmentList = data.data.environment;
						this.environmentIds = data.data.environment_ids;
						this.auth = data.data.auth;
						this.memberIds = data.data.member_ids; //白名单成员表
						this.isUseWhitelist = Number(data.data.mode) === 2; //mode为1不启用白名单，2是启用白名单
						// 根据environmentIds对比获取已分配环境和平台
						environmentList.forEach(item => {
							if (this.environmentIds.includes(item.id)) {
								item.isChecked = true;
							} else {
								item.isChecked = false;
							}
						});
						//向平台列表中添加平台对应的所有的环境
						platformList.forEach(i => {
							i.environmentList = environmentList.filter(item => item.platform_id == i.id);
							i.isChecked = i.environmentList.every(item => item.isChecked);
						});
						this.platformList = platformList;
						this.environmentList = environmentList;

						this.getMemberList();
					}
				});
				//生成部门树
				this.getDepartTree();
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.modal_body {
	.flex();
	padding: 0 10px;
	.body_left {
		width: 274px;
		::v-deep .ant-tabs-bar {
			border-bottom: none;
		}
		.left_item {
			height: 342px;
			padding: 24px 16px 0;
			border: 1px solid #e8e8e8;
			border-radius: 4px;
			overflow-y: auto;
			.checkbox {
				margin: 10px 0;
			}
			.environment_list {
				margin-top: 10px;
				.environment_item {
					margin-bottom: 10px;
					padding-right: 20px;
					cursor: pointer;
					.flex();
					.environment_item_txt {
						width: 200px;
						.ellipsis();
					}
					::v-deep .anticon-check {
						display: none;
					}
					&.active {
						color: #4c84ffed;
						.anticon-check {
							display: inline-block;
						}
					}
				}
			}
		}
	}
	.body_right {
		width: 274px;
		.right_title {
			height: 36px;
			margin-bottom: 23px;
			color: #646d82;
			font-size: 16px;
			line-height: 36px;
		}
		.right_item {
			height: 342px;
			padding: 24px 16px 0;
			border: 1px solid #e8e8e8;
			border-radius: 4px;
			overflow-y: auto;

			.environment_list_checked {
				margin-top: 10px;
				.environment_item_checked {
					margin-bottom: 10px;
					height: 30px;
					padding: 0 14px;
					line-height: 30px;
					color: #4c84ffed;
					border: 1px solid #cfdbff;
					background-color: #f0f4ff;
					.flex();
					.environment_item_checked_txt {
						width: 190px;
						.ellipsis();
					}
				}
			}
		}
	}
}
</style>
